import React, { useEffect, useRef } from 'react'
import { Counter, Overlay } from './Styles';
import { Anton } from 'next/font/google'
import { gsap } from 'gsap'
import { useLenis } from '@studio-freight/react-lenis'

const anton = Anton({
  weight: ['400'],
  subsets: ['latin'],
})

export const Preloader = ({ onComplete }) => {
  const counterRef = useRef(null)
  const overlayRef = useRef(null)
  const lenis = useLenis()

  useEffect(() => {
    const counterElement = counterRef.current

    const animation = gsap.to(counterElement, {
      duration: 3.5,
      innerHTML: 100,
      ease: 'power2.inOut',
      onStart: () => {
        lenis && lenis.stop()
        counterElement.innerHTML = 0
      },
      onUpdate: () => counterElement.innerHTML = Math.round(parseFloat(counterElement.innerHTML)),
    })

    animation.eventCallback('onComplete', () => {
      const tl = gsap.timeline({
        onComplete: () => {
          lenis && lenis.start()

          if (onComplete) onComplete()
        },
      })

      tl.to('.counter', {
        duration: 0.25,
        opacity: 0,
        ease: 'linear',
      })

      tl.to('.bar', {
        duration: 1.5,
        height: 0,
        ease: 'power4.inOut',
        stagger: 0.1,
      })
    })

    return () => animation.kill()
  }, [lenis, onComplete])

  return (
    <>
      <Counter ref={counterRef} className={`${anton.className} counter`} />
      <Overlay ref={overlayRef} className='overlay'>
        {Array.from({ length: 10 }).map((_, index) => (
          <div key={index} className='bar'></div>
        ))}
      </Overlay>
    </>
  )
}