import { useEffect, useState } from 'react'
import '../styles/reset.css'
import '../styles/lenis.css'
import '../styles/globals.css'
import 'atropos/css'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false
import { useLenis } from '@studio-freight/react-lenis'
import { Preloader } from 'components/Preloader'


function MyApp({ Component, pageProps }) {
  const [showPreloader, setShowPreloader] = useState(true)
  const lenis = useLenis()

  useEffect(() => {
    if (!lenis) return
    else lenis.stop()
  }, [lenis])

  const handlePreloaderComplete = () => {
    setShowPreloader(false)

    if (lenis) lenis.start()
  }

  return (
    <>
      {showPreloader && <Preloader onComplete={handlePreloaderComplete} />}
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
