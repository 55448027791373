import styled from 'styled-components';

const Counter = styled.span`
  display: flex;
  position: fixed;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: calc(var(--z-loader) + 1);
  padding: 1rem;
  width: 100%;
  height: 100%;
  color: var(--text-muted);
  font-size: 20vw;
  opacity: 1;
  transition: opacity 0.25s ease-out;
`

const Overlay = styled.div`
  display: flex;
  position: fixed;
  z-index: var(--z-loader);
  width: 100vw;
  height: 100vh;

  .bar {
    background-color: var(--background-secondary);
    width: 10vw;
    height: 105vh;
  }
`

export { Counter, Overlay }